import("@sass/components/image-view.scss")

window.addEventListener("DOMContentLoaded", async function () {
    const heroComponents = document.querySelectorAll(".wp-block-tutkit-hero-with-video")
    if (heroComponents.length > 0) {
        heroComponents.forEach(function (component) {
            const playButtons = component.querySelectorAll(".icon-play")

            playButtons.forEach((button) => {
                button.addEventListener("click", function (e) {
                    e.preventDefault()

                    const html = `
                    <div class="popup-backdrop image canClose" style="width: 100%; height: 100%">
                        <div title="schließen" class="icon icon-close-dark pointer canClose close"></div>
                        <div class="container-image flex flex-center center">
                            <video controls="controls" autoplay="autoplay" controlsList="nodownload" style="width: 100%" src="${button.attributes["data-href"]?.nodeValue}"></video>
                        </div>
                    </div>
                    `
                    const div = document.createElement("div")
                    div.className = `popup popup-blur popup-open popup-auto-width`
                    div.style.padding = "0px"
                    div.style.maxWidth = "none"
                    div.innerHTML = html
                    component.append(div)

                    component.querySelector(".close").addEventListener("click", () => {
                        component.querySelector(".popup").remove()
                    })

                    document.addEventListener("mouseup", (e) => {
                        const parentElement = component.querySelector(".container-image")
                        if (!parentElement.contains(e.target)) {
                            e.target.closest(".popup").remove()
                        }
                    })

                    document.addEventListener("keydown", (evt) => {
                        evt = evt || window.event
                        let isEscape = false
                        if ("key" in evt) {
                            isEscape = evt.key === "Escape" || evt.key === "Esc"
                        } else {
                            isEscape = evt.keyCode === 27
                        }
                        if (isEscape) {
                            const popup = component.querySelector(".popup")
                            if (popup) {
                                evt.stopPropagation()
                                popup.remove()
                            }
                        }
                    })
                })
            })
        })
    }
})
