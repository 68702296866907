import("@sass/laraberg/video-with-texts.scss")

window.addEventListener("DOMContentLoaded", async function () {
    const videoWithTexts = document.querySelectorAll(".wp-block-tutkit-video-with-texts")

    if (videoWithTexts.length > 0) {
        videoWithTexts.forEach((component) => {
            let playButton = component.querySelector(".icon-play")
            let videoPlayer = component.querySelector(".video-player")

            playButton.addEventListener("click", function () {
                videoPlayer.play()
                component.classList.add("video-playing")

                videoPlayer.addEventListener("ended", function () {
                    component.classList.remove("video-playing")
                })
            })
        })
    }
})
