/* global tk */
import("@sass/laraberg/google-review-slider.scss")
import Swiper from "swiper"
import { Navigation } from "swiper/modules"

Swiper.use([Navigation])
const Slider = Swiper

document.addEventListener("DOMContentLoaded", () => {
    tk.google_review_slider = (element) => {
        return {
            element: element,
            slides: null,
            pagination: null,
            slideItem: null,

            async init() {
                this.renderReviews()
            },

            renderReviews() {
                let slider = document.querySelector(".wp-block-tutkit-google-reviews-slider .swiper")

                const countLines = (element) => {
                    const style = getComputedStyle(element)
                    let divHeight = element.scrollHeight
                    let lineHeight = parseInt(style.lineHeight)
                    return divHeight / lineHeight
                }

                const checkReview = (items) => {
                    items.forEach((e) => {
                        if (countLines(e) > 5) {
                            e.classList.remove("after-dn")
                        } else {
                            e.classList.add("after-dn")
                        }
                    })
                }

                const stars = (rate) => {
                    const fullStars = rate
                    const emptyStars = 5 - rate
                    return `
                        ${'<img src="/images/svg/rate-star-button-gold.svg" alt="Google Reviews" loading="lazy">'.repeat(fullStars)}
                        ${'<img src="/images/svg/rate-star-button-grey.svg" alt="Google Reviews" loading="lazy">'.repeat(emptyStars)}
                    `
                }

                ;(async () => {
                    try {
                        let response = await window.globalJs.axios.post(
                            `/${window.globalJs.route.locale}/get-google-reviews`,
                        )
                        if (response) {
                            let reviews = response.data
                                .map((singleReview) => {
                                    if (singleReview) {
                                        return `
                                        <div class="swiper-slide">
                                            <div class="google-review-container">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="google-reviews-title">
                                                            <img src="/images/svg/icons8-google.svg" alt="Google Reviews" loading="lazy">
                                                            ${window.globalJs.translations.laraberg.google_reviews}
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="google-review-rate rating-${singleReview.rate}">
                                                            ${stars(singleReview.rate)}
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="google-review-review_add_date">
                                                            ${singleReview.format_add_date}
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="google-review-review">
                                                            <i class="icon icon-open-small open-close"></i>
                                                            ${singleReview.review}
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="google-review-full_name">
                                                            ${singleReview.full_name}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    `
                                    }
                                })
                                .join("")
                            let googleReviewsSlider = document.querySelector(
                                ".wp-block-tutkit-google-reviews-slider .swiper .swiper-wrapper",
                            )
                            googleReviewsSlider.innerHTML = reviews

                            let reviewText = document.querySelectorAll(
                                ".wp-block-tutkit-google-reviews-slider .google-review-container .google-review-review",
                            )
                            let openClose = document.querySelectorAll(
                                ".wp-block-tutkit-google-reviews-slider .google-review-container .google-review-review .open-close",
                            )
                            checkReview(reviewText)
                            reviewText.forEach((review) => {
                                if (!review.classList.contains("after-dn")) {
                                    review.addEventListener("click", (e) => {
                                        e.target.classList.toggle("description-expanded")
                                    })
                                }
                            })

                            openClose.forEach((reviewBtn) => {
                                reviewBtn.addEventListener("click", (e) => {
                                    e.target.parentElement.classList.toggle("description-expanded")
                                })
                            })

                            const contentSlider = new Slider(slider, {
                                speed: 1000,
                                autoplay: true,
                                slidesPerView: 1,
                                spaceBetween: 30,
                                loop: true,
                                autoHeight: false,
                                navigation: {
                                    nextEl: `.google-review-next`,
                                    prevEl: `.google-review-prev`,
                                },
                                pagination: {
                                    el: ".google-review-pagination",
                                },
                                breakpoints: {
                                    992: {
                                        slidesPerView: 2,
                                    },
                                },
                            })
                            contentSlider.init()
                        }
                        window.addEventListener("resize", () => {
                            checkReview(
                                document.querySelectorAll(
                                    ".wp-block-tutkit-google-reviews-slider .google-review-container .google-review-review",
                                ),
                            )
                        })
                    } catch (error) {
                        console.error(error)
                    }
                })()
            },
        }
    }

    tk.onLoad(function () {
        tk.select(".wp-block-tutkit-google-reviews-slider").forEach(function (item) {
            tk.google_review_slider(item).init()
        })
    })
})
