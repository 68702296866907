import("@sass/laraberg/categories-with-icons.scss")

document.addEventListener("DOMContentLoaded", () => {
    tk.categoriesWithIcons = (categoriesSection) => {
        return {
            elements: {
                categories: categoriesSection.querySelector(".categories-with-icons").dataset.categories,
            },

            async init() {
                this.renderCategories()
            },

            async renderCategories() {
                let categories = this.elements.categories
                let arrCategories = categories.split(",").map((i) => Number(i))
                let html = ""

                try {
                    let response = await window.globalJs.axios.post(
                        `/${window.globalJs.route.locale}/get-categories`,
                        arrCategories,
                    )
                    if (response) {
                        const categoriesData = response.data.categories
                        categoriesData.forEach((category) => {
                            html += `
                                <a href="${category.url}" class="categories-with-icons__item">
                                    <div class="categories-with-icons__item--icon">
                                        <img src="${category.image_white.url}" alt="${category.image_white.alt}" />
                                    </div>
                                    <p class="categories-with-icons__item--name">${category.text_icon ? category.text_icon : category.name}</p>
                                </a>`
                        })
                        categoriesSection.querySelector(".categories-with-icons").innerHTML = html
                    }
                } catch (error) {
                    console.error("Error loading categories:", error)
                }
            },
        }
    }

    tk.onLoad(() => {
        tk.select(".wp-block-tutkit-categories-with-icons").forEach((item) => {
            tk.categoriesWithIcons(item).init()
        })
    })
})
