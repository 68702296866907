/* global tk */
import("@sass/laraberg/quote-slider.scss")
import Swiper from "swiper"
import { Navigation, Pagination } from "swiper/modules"
Swiper.use([Navigation, Pagination])

tk.quote_slider = function (element) {
    return {
        element: element,
        slides: null,
        pagination: null,
        slideItem: null,
        async init() {
            function wrap(el, wrapper) {
                el.parentNode.insertBefore(wrapper, el)
                wrapper.appendChild(el)
            }

            document.querySelectorAll(".wp-block-tutkit-quote-slider").forEach(slider => {
                let pagination = document.createElement("div")
                pagination.className = "swiper-pagination"
                slider.append(pagination)
            })

            let tutkitQuote = document.querySelectorAll(".wp-block-tutkit-quote-slider .wp-block-tutkit-quote")
            let tutkitQuoteWithoutImg = document.querySelectorAll(
                ".wp-block-tutkit-quote-slider .wp-block-tutkit-quote-without-img",
            )

            if (tutkitQuote.length > 0) {
                for (let i = 0; i < tutkitQuote.length; i++) {
                    let div = document.createElement("div")
                    div.className = "swiper-slide"
                    wrap(tutkitQuote[i], div)
                }
            }

            if (tutkitQuoteWithoutImg.length > 0) {
                for (let i = 0; i < tutkitQuoteWithoutImg.length; i++) {
                    let div = document.createElement("div")
                    div.className = "swiper-slide"
                    wrap(tutkitQuoteWithoutImg[i], div)
                }
            }

            new Swiper(`.wp-block-tutkit-quote-slider .swiper`, {
                speed: 1000,
                slidesPerView: 1,
                spaceBetween: 30,
                loop: true,
                autoHeight: true,
                navigation: {
                    nextEl: `.swiper-button-next`,
                    prevEl: `.swiper-button-prev`,
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            })
        },
    }
}

tk.onLoad(function () {
    tk.select(".wp-block-tutkit-quote-slider").forEach(function (item) {
        tk.quote_slider(item).init()
    })
})
