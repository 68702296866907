document.addEventListener("DOMContentLoaded", () => {
    let buttonsDownload = document.querySelectorAll(".download-button")
    if (buttonsDownload.length > 0) {
        buttonsDownload.forEach((button) => {
            let url = button.querySelector("a").getAttribute("href")
            if (url) {
                let download = url.substring(url.lastIndexOf("/") + 1)
                button.querySelector("a").setAttribute("download", download)
            }
        })
    }
})
