/* global tk */
import("@sass/laraberg/content-module-overview.scss")
import { isTouchEnabled, shouldBeLazyLoaded } from "@/libs/helpers.js"
import _debounce from "lodash/debounce"

tk.categoriesModuleOverview = async (categoriesSection) => {
    const { default: Swiper } = await import("swiper")
    const { Navigation } = await import("swiper/modules")
    Swiper.use([Navigation])

    const Slider = Swiper

    return {
        currentCategoryIndex: 0,
        elements: {
            titles: categoriesSection.querySelectorAll(".category-titles > div"),
            images: categoriesSection.querySelectorAll(".category-images > picture"),
            packages: categoriesSection.querySelectorAll(".category-images > div"),
            texts: categoriesSection.querySelectorAll(".category-texts > div"),
        },
        touchEnabled: isTouchEnabled(),
        loadedImages: [],

        init() {
            this.renderPackage()
            this.loadImage(0)
            this.bindTitleEvent()
            this.changeActiveCategory()
            this.elements.packages[this.currentCategoryIndex].classList.add("fade-in")
            this.bindLazyLoad()
        },

        bindTitleEvent() {
            this.elements.titles.forEach((title, index) => {
                title.addEventListener("click", () => {
                    this.selectCategory(index)
                })
            }, this)
        },

        selectCategory(index) {
            this.currentCategoryIndex = index
            this.changeActiveCategory()

            const sliderSelector = `.category-images > div .content-slider-${index}`
            const nextSelector = `.next-${index}`
            const prevSelector = `.prev-${index}`

            const swiper = new Slider(sliderSelector, {
                slidesPerView: 1,
                centeredSlides: false,
                spaceBetween: 30,
                onlyExternal: true,
                effect: "slide",
                direction: "horizontal",
                speed: 1000,
                coverflowEffect: { slideShadows: false },
                parallax: false,
                loop: true,
                keyboard: true,
                a11y: true,
                navigation: {
                    nextEl: nextSelector,
                    prevEl: prevSelector,
                },
                breakpointsInverse: true,
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                    },
                    992: {
                        slidesPerView: 3,
                    },
                    1200: {
                        slidesPerView: 5,
                    },
                },
                on: {
                    init: function () {
                        const totalSlides = this.slides.length
                        const visibleSlides = this.params.slidesPerView
                        const nextEl = document.querySelector(nextSelector)
                        const prevEl = document.querySelector(prevSelector)
                        if (totalSlides <= visibleSlides) {
                            nextEl.style.display = "none"
                            prevEl.style.display = "none"
                        } else {
                            nextEl.style.display = "block"
                            prevEl.style.display = "block"
                        }
                    },
                    resize: function () {
                        const totalSlides = this.slides.length
                        const visibleSlides = this.params.slidesPerView
                        const nextEl = document.querySelector(nextSelector)
                        const prevEl = document.querySelector(prevSelector)
                        if (totalSlides <= visibleSlides) {
                            nextEl.style.display = "none"
                            prevEl.style.display = "none"
                        } else {
                            nextEl.style.display = "block"
                            prevEl.style.display = "block"
                        }
                    },
                },
            })
            swiper.update()
        },

        renderPackage() {
            let packages = this.elements.packages

            packages.forEach((item, i) => {
                let arrPackages = item.innerHTML.split(",").map((i) => Number(i))
                const headers = {
                    "Content-Type": "application/json; charset=UTF-8",
                    "X-CSRF-TOKEN": document.querySelector("meta[name=csrf-token]").content,
                    "X-Robots-Tag": "noindex, nofollow",
                }
                ;(async () => {
                    let response = await window.globalJs.axios.post(
                        `/${window.globalJs.route.locale}/getpackage`,
                        arrPackages,
                        { headers: headers },
                    )
                    let pack = response.data

                    let html = `
                        <div class="content-slider-${i} swiper">
                            <div class="swiper-wrapper">
                                ${pack
                                    .map((singlePackage) => {
                                        if (singlePackage) {
                                            let titleCode = ""
                                            if (singlePackage.package_cover_title !== null) {
                                                if (singlePackage.package_cover_title.includes("<br>")) {
                                                    let firstPart =
                                                        singlePackage.package_cover_title.split("<br>")[0] ?? ""
                                                    let secondPart =
                                                        singlePackage.package_cover_title.split("<br>")[1] ?? ""
                                                    titleCode = `
                                                    <span class="package-image-text__title">${firstPart}</span>
                                                    <span class="package-image-text__title">${secondPart}</span>
                                                    `
                                                } else if (singlePackage.package_cover_title.includes("-<wbr>")) {
                                                    let firstPart =
                                                        singlePackage.package_cover_title.split("-<wbr>")[0] ?? ""
                                                    let secondPart =
                                                        singlePackage.package_cover_title.split("-<wbr>")[1] ?? ""
                                                    titleCode = `
                                                    <span class="package-image-text__title">${firstPart}-</span>
                                                    <span class="package-image-text__title">${secondPart}</span>
                                                    `
                                                } else {
                                                    titleCode = `<span class="package-image-text__title">${singlePackage.package_cover_title}</span>`
                                                }
                                            } else {
                                                titleCode = `<span class="package-image-text__title"> </span>`
                                            }
                                            return `
                                            <div class="swiper-slide slider-before-load">
                                                <a href="/${window.globalJs.route.locale}/${window.globalJs.translations.routes.packages}/${singlePackage.id}-${singlePackage.slug}" alt="${singlePackage.title}" title="${singlePackage.title}" target="_blank">
                                                    <div class="package-image-text">
                                                        ${titleCode}
                                                    </div>
                                                    <picture class="cover">
                                                        <source type="image/avif"
                                                                srcset=${`/storage/media/` + singlePackage.image_featured.path.replace(/\.(jpg|jpeg|png|gif|bmp|tiff|webp)$/i, ".avif")}/>
                                                        <img alt="${singlePackage.title}" title="${singlePackage.title}"
                                                                 src=${`/storage/media/` + singlePackage.image_featured.path.replace(/\.(jpg|jpeg|png|gif|bmp|tiff|avif)$/i, ".webp")}/>
                                                    </picture>
                                                </a>
                                            </div>
                                        `
                                        }
                                    })
                                    .join("")}
                            </div>
                        </div>
                        <div class="swiper-button-prev prev-${i}"></div>
                        <div class="swiper-button-next next-${i}"></div>`

                    item.innerHTML = html
                    let slider = document.querySelector(`.category-images > div .content-slider-${i}`)
                    const contentSlider = new Slider(slider, {
                        slidesPerView: 1,
                        centeredSlides: false,
                        spaceBetween: 30,
                        onlyExternal: true,
                        effect: "slide",
                        direction: "horizontal",
                        speed: 1000,
                        coverflowEffect: { slideShadows: false },
                        parallax: true,
                        loop: true,
                        keyboard: true,
                        a11y: true,
                        navigation: {
                            nextEl: `.next-${i}`,
                            prevEl: `.prev-${i}`,
                        },
                        breakpointsInverse: true,
                        breakpoints: {
                            768: {
                                slidesPerView: 2,
                            },
                            992: {
                                slidesPerView: 3,
                            },
                            1200: {
                                slidesPerView: 5,
                            },
                        },
                        on: {
                            init: function () {
                                const totalSlides = this.slides.length
                                const visibleSlides = this.params.slidesPerView
                                const nextEl = document.querySelector(`.next-${i}`)
                                const prevEl = document.querySelector(`.prev-${i}`)
                                if (totalSlides <= visibleSlides) {
                                    nextEl.style.display = "none"
                                    prevEl.style.display = "none"
                                } else {
                                    nextEl.style.display = "block"
                                    prevEl.style.display = "block"
                                }
                            },
                            resize: function () {
                                const totalSlides = this.slides.length
                                const visibleSlides = this.params.slidesPerView
                                const nextEl = document.querySelector(`.next-${i}`)
                                const prevEl = document.querySelector(`.prev-${i}`)
                                if (totalSlides <= visibleSlides) {
                                    nextEl.style.display = "none"
                                    prevEl.style.display = "none"
                                } else {
                                    nextEl.style.display = "block"
                                    prevEl.style.display = "block"
                                }
                            },
                        },
                    })
                    contentSlider.init()
                    let sliderBeforeLoad = document.querySelectorAll(`.content-slider-${i} .slider-before-load`)
                    if (sliderBeforeLoad.length > 0) {
                        setTimeout(() => {
                            sliderBeforeLoad.forEach((e) => {
                                e.classList.remove("slider-before-load")
                            })
                        }, 20000)
                    }
                })()
            })
        },

        changeActiveCategory() {
            if (this.elements.images.length > 0) {
                this.elements.images.forEach((image) => image.classList.remove("category-active"))
                this.elements.images[this.currentCategoryIndex].classList.add("category-active")
            }
            if (this.elements.packages.length > 0) {
                this.elements.packages.forEach((content) => content.classList.remove("category-active"))
                this.elements.packages.forEach((content) => content.classList.remove("fade-in"))
                this.elements.packages[this.currentCategoryIndex].classList.add("category-active")
                setTimeout(() => {
                    this.elements.packages[this.currentCategoryIndex].classList.add("fade-in")
                }, 100)
            }
            this.elements.titles.forEach((title) => title.classList.remove("category-active"))
            this.elements.titles[this.currentCategoryIndex].classList.add("category-active")
            this.elements.texts.forEach((title) => title.classList.remove("category-active"))
            this.elements.texts[this.currentCategoryIndex].classList.add("category-active")
        },

        bindLazyLoad() {
            if (!this.touchEnabled) {
                this.elements.titles.forEach((title, index) => {
                    title.addEventListener("mouseover", () => {
                        this.loadImage(index)
                    })
                })
            } else {
                window.addEventListener(
                    "scroll",
                    _debounce(() => {
                        if (shouldBeLazyLoaded(categoriesSection)) {
                            this.elements.images.forEach((image, index) => {
                                this.loadImage(index)
                            })
                        }
                    }, 50),
                )
            }
        },

        loadImage(index) {
            if (this.loadedImages.includes(index)) return false
            let activeImage = {}
            index
                ? (activeImage = this.elements.images[index])
                : (activeImage = this.elements.images[this.currentCategoryIndex])
            activeImage && (activeImage.src = activeImage.dataset["backgroundUrl"])
            this.loadedImages.push(index)
        },
    }
}

tk.onLoad(() => {
    tk.select(".wp-block-tutkit-content-module-overview").forEach(async (item) => {
        const moduleOverview = await tk.categoriesModuleOverview(item)
        moduleOverview.init()
    })
})
