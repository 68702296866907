/* import */
import("@sass/laraberg/email-form.scss")
import { openLoginRegistrationPopup } from "@/libs/helpers.js"
;(() => {
    document.addEventListener("DOMContentLoaded", async () => {
        const forms = document.querySelectorAll(".tk-email-form, .test-account")
        if (forms.length > 0) {
            forms.forEach(function (form) {
                let csrf = document.querySelector("meta[name=csrf-token]").content
                let input = document.createElement("input")
                input.name = "_token"
                input.value = csrf
                input.type = "hidden"
                form.append(input)

                let isPreview = document.createElement("input")
                isPreview.name = "is_preview"
                isPreview.value = 1
                isPreview.type = "hidden"
                form.append(isPreview)

                form.querySelector("button").addEventListener("click", (e) => {
                    e.preventDefault()
                    openLoginRegistrationPopup("registration")
                })
            })

            let formsWithText = document.querySelectorAll(".tk-email-form.--with-before-content")
            if (formsWithText.length) {
                const spanElement = document.createElement("span")
                spanElement.textContent = window.globalJs.translations.forms.start_now
                spanElement.classList.add("tk-email-form__label")
                formsWithText.forEach((el) => el.appendChild(spanElement))
            }
        }
    })
})()
