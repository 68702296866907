import("@sass/laraberg/email-form-for-tour.scss")

;(async () => {
    const forms = document.querySelectorAll(".professional-purposes-form")
    if (forms.length > 0) {
        forms.forEach((form) => {
            let csrf = document.querySelector("meta[name=csrf-token]").content
            let input = document.createElement("input")
            let translation = window.globalJs.translations.content
            input.name = "_token"
            input.value = csrf
            input.type = "hidden"
            form.append(input)

            let isPreview = document.createElement("input")
            isPreview.name = "is_preview"
            isPreview.value = 1
            isPreview.type = "hidden"
            form.append(isPreview)

            let emailInput = form.querySelector(".professional-purposes-email") ?? null
            let companyInput = form.querySelector(".professional-purposes-company") ?? null
            let nameInput = form.querySelector(".professional-purposes-name") ?? null

            const creteErrorMessage = (string) => {
                let span = document.createElement("span")
                span.innerText = string
                span.classList.add("special-message")
                return span
            }

            form.querySelector('input[type="checkbox"]').removeAttribute("required")
            form.querySelectorAll("input").forEach((item) => {
                item.addEventListener("input", (e) => {
                    if (e.target.closest(".col-12").querySelector(".special-message")) {
                        e.target.closest(".col-12").querySelector(".special-message").remove()
                    }
                    item.classList.remove("error")

                    if (e.target.value === "") {
                        e.target.closest(".col-12").querySelector(".desc-field-small-text").classList.add("disable")
                    } else {
                        e.target.closest(".col-12").querySelector(".desc-field-small-text")?.classList.remove("disable")
                    }
                })
            })

            form.addEventListener("submit", function (event) {
                event.preventDefault()
                if (form.querySelector('input[type="checkbox"]')) {
                    form.querySelector('input[type="checkbox"]').classList.remove("error")
                }

                if (form.querySelector("p")) {
                    form.querySelector("p").remove()
                }

                form.querySelectorAll("input").forEach((item) => {
                    item.classList.remove("error")
                })

                form.querySelectorAll("span").forEach((item) => {
                    if (!item.classList.contains("desc-field-small-text")) {
                        item.remove()
                    }
                })

                if (companyInput.value === "") {
                    companyInput.classList.add("error")
                    companyInput.parentNode.append(creteErrorMessage(translation.enter_company))
                    companyInput.closest(".col-12").querySelector(".desc-field-small-text").classList.add("disable")
                }

                if (nameInput.value === "") {
                    nameInput.classList.add("error")
                    nameInput.parentNode.append(creteErrorMessage(translation.enter_name))
                    nameInput.closest(".col-12").querySelector(".desc-field-small-text").classList.add("disable")
                }

                if (emailInput.value === "") {
                    emailInput.classList.add("error")
                    emailInput.parentNode.append(creteErrorMessage(translation.enter_phone))
                    emailInput.closest(".col-12").querySelector(".desc-field-small-text").classList.add("disable")
                }

                if (!form.querySelector('input[type="checkbox"]').checked) {
                    form.querySelector('input[type="checkbox"]').classList.add("error")
                }

                if (
                    emailInput.value === "" ||
                    nameInput.value === "" ||
                    companyInput.value === "" ||
                    !form.querySelector('input[type="checkbox"]').checked
                ) {
                    return
                }

                let protocol = window.location.href.indexOf("https://") === 0 ? "https://" : "http://"

                let email = document.createElement("input")
                email.name = "email"
                email.type = "hidden"
                email.value = emailInput.value
                form.append(email)

                let company = document.createElement("input")
                company.name = "company"
                company.type = "hidden"
                company.value = companyInput.value
                form.append(company)

                let name = document.createElement("input")
                name.name = "name"
                name.type = "hidden"
                name.value = nameInput.value
                form.append(name)

                let data = {
                    name: form.name.value,
                    email: form.email.value,
                    company: form.company.value,
                }

                fetch(protocol + window.location.host + "/tour-form", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=UTF-8",
                        "X-CSRF-TOKEN": form._token.value,
                    },
                    body: JSON.stringify(data),
                })
                    .then((res) => res.json())
                    .then((response) => {
                        if (response) {
                            let success = document.createElement("p")
                            success.innerText = translation.request_sent
                            success.className = "success-message"
                            form.append(success)
                        }
                    })
            })
        })
    }
})()
