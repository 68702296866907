import("@sass/laraberg/video-with-poster.scss")

window.addEventListener("DOMContentLoaded", async function () {
    const videoWithPoster = document.querySelectorAll(".wp-block-tutkit-video-with-poster")

    if (videoWithPoster.length > 0) {
        videoWithPoster.forEach((component) => {
            let playButton = component.querySelector(".icon-play")
            let videoPlayer = component.querySelector(".video-player")

            playButton.addEventListener("click", function () {
                videoPlayer.play()
                component.classList.add("video-playing")

                videoPlayer.addEventListener("ended", function () {
                    component.classList.remove("video-playing")
                })
            })
        })
    }
})
