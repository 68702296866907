document.addEventListener(
    "DOMContentLoaded",
    async () => {
        const appElement = document.getElementById("price-models-app")

        if (appElement) {
            const [PriceModule] = await Promise.all([
                import("@/components/Frontend/PriceModule.vue"),
                import("@sass/laraberg/price-module.scss"),
            ])

            const { createApp } = await import("vue")

            const PriceModuleApp = createApp({})
            PriceModuleApp.component("price-models", PriceModule.default)
            PriceModuleApp.mount("#price-models-app")
        }
    },
    { passive: true },
)
