import("@sass/laraberg/trainer-list.scss")
import { createApp } from "vue"
import TrainerList from "@/components/Frontend/TrainerList.vue"
;(function () {
    window.addEventListener(
        "DOMContentLoaded",
        async function () {
            const appElement = document.getElementById("trainer-list-app")
            if (appElement) {
                const TrainerListApp = createApp({})
                TrainerListApp.component("trainer-list", TrainerList)
                TrainerListApp.mount("#trainer-list-app")
            }
        },
        { passive: true },
    )
})()
