/* global tk */
import("@sass/laraberg/accordeon-with-images.scss")

tk.accordeonWithImages = function (element) {
    function toggleClick(elementsAccordeon, images, indexOpen) {
        elementsAccordeon.forEach((element, indexCurrent) => {
            let currentImg = images[indexCurrent]

            if (indexOpen === indexCurrent) {
                element.classList.add("open")
                currentImg.classList.add("active")
            } else {
                element.classList.remove("open")
                currentImg.classList.remove("active")
            }
        })
    }

    function copyImages(elementsAccordeon, images, index) {
        elementsAccordeon.forEach((element, indexCurrent) => {
            let currentImg = images[indexCurrent].cloneNode(true) // Cloning the image

            if (index === indexCurrent) {
                element.querySelector(".item-accordeon").append(currentImg)
            }
        })
    }

    return {
        element: element,
        init() {
            const elementsAccordeon = element.querySelectorAll(".container-accordeon > div")
            const images = element.querySelectorAll(".container-image > picture")
            elementsAccordeon.forEach((elementAccordeon, index) => {
                elementAccordeon.addEventListener("click", function () {
                    toggleClick(elementsAccordeon, images, index)
                })
                copyImages(elementsAccordeon, images, index)
            })
        },
    }
}

tk.onLoad(function () {
    tk.select(".wp-block-tutkit-accordeon-with-images").forEach(function (item) {
        tk.accordeonWithImages(item).init()
    })
})
