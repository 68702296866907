<template>
    <div id="infinite-list">
        <div class="tutkit-trainer-list-trainer" v-for="(trainer, index) in trainers" :key="index">
            <picture>
                <source
                    type="image/avif"
                    :srcset="trainer.photo_file.url.replace(/\.(jpg|jpeg|png|gif|bmp|tiff|webp)$/i, '.avif')"
                />
                <img
                    type="image/webp"
                    :alt="trainer.full_name"
                    :title="trainer.full_name"
                    loading="lazy"
                    :src="trainer.photo_file.url.replace(/\.(jpg|jpeg|png|gif|bmp|tiff|avif)$/i, '.webp')"
                />
            </picture>
            <p class="trainer-name">{{ trainer.full_name }}</p>
            <a :href="authorsUrl + '/' + trainer.id + '-' + trainer.slug" class="overlay-container">
                <div class="trainer-link">{{ t.to_trainers_page }}</div>
            </a>
        </div>
    </div>
</template>

<script>
import axios from "@/app/axios.js"
const globalJs = window.globalJs
export default {
    name: "TrainerList",
    props: [],
    data() {
        return {
            trainers: [],
            limit: 24,
            busy: false,
            scrollcount: 0,
            t: globalJs.translations.buttons,
            authorsUrl: globalJs.route.authors,
        }
    },
    mounted() {
        let self = this
        let windowHeight = window.innerHeight
        let listElm
        setTimeout(() => {
            listElm = document.querySelector("main")
        }, 1)

        // Detect when scrolled to bottom.
        window.addEventListener("scroll", () => {
            if (listElm.scrollTop + listElm.clientHeight <= window.scrollY + windowHeight) {
                if (self.scrollcount <= 0) {
                    self.scrollcount++
                    self.loadMore()
                }
            }
        })
        // Initially load some items.
        self.loadMore()
    },
    methods: {
        loadMore() {
            let self = this
            //console.log('Adding ' + self.limit + ' more data results');
            self.busy = true
            axios.post(this.authorsUrl).then((response) => {
                let arr = Object.values(response.data.authors)
                const append = arr.slice(self.trainers.length, self.trainers.length + self.limit)
                self.trainers = self.trainers.concat(append)
                self.busy = false
                self.scrollcount = 0
            })
        },
    },
}
</script>
