import("@sass/laraberg/info-boxes.scss")

/* global tk */

/*
tk.infoBoxes = function (element) {
    return {
        element: element,
        currentStep: 0,
        $elementContainerBoxes: null,
        $body: null,
        rectContainerBoxes: null,
        init() {
            this.$elementContainerBoxes = this.element.querySelector(".container-boxes")
            this.$body = document.querySelector("html")
            this.rect = this.element.getBoundingClientRect()

            setTimeout(this.initScrollEvent.bind(this), 50)

            this.scrollEvent()

            return this
        },
        scrollEvent() {
            const top = this.$body.scrollTop
            const bottom = top + window.innerHeight

            let scrollPercent = 0
            try {
                scrollPercent = parseInt(((bottom - this.rect.top) / (this.rect.bottom - this.rect.top)) * 2)
            } catch (e) {
                console.error(e)
            }

            scrollPercent = Math.min(2, scrollPercent + 1)

            if (scrollPercent !== this.currentStep) {
                this.$elementContainerBoxes.classList.remove(`step-${this.currentStep}`)
                this.$elementContainerBoxes.classList.add(`step-${scrollPercent}`)

                this.currentStep = scrollPercent
            }
        },
        initScrollEvent() {
            window.addEventListener("scroll", this.scrollEvent.bind(this))
        },
    }
}

tk.onLoad(async function () {
    tk.select(".wp-block-tutkit-info-boxes").forEach(function (item) {
        tk.infoBoxes(item).init().scrollEvent()
    })
})
*/
