import("@sass/laraberg/company-plan.scss")

;(() => {
    document.addEventListener("DOMContentLoaded", async () => {
        const buttonOpenCompanyPlan = document.getElementById("openCompanyPlan")
        const hiddenCompanyPlan = document.querySelector(".wp-block-tutkit-company-plan .hide-content-wrapper")

        if (buttonOpenCompanyPlan && hiddenCompanyPlan) {
            buttonOpenCompanyPlan.addEventListener("click", (e) => {
                e.preventDefault()
                hiddenCompanyPlan.classList.toggle("openHiddenCompanyPlan")
            })
        }
    })
})()
