import Glide, { Controls, Breakpoints, Swipe } from "@glidejs/glide/dist/glide.modular.esm"
import _debounce from "lodash/debounce"
import("@sass/components/slider.scss")
// slider moved soll insgesamt nur einmal gemeldet werden
const moveAfterFunction = _debounce(() => {
    window.eventBus && window.eventBus.$emit("slider-moved")
}, 50)

document.addEventListener(
    "DOMContentLoaded",
    () => {
        const sliders = document.querySelectorAll(".slider")
        sliders.forEach((slider) => {
            const noSliderForMobile = slider.classList.contains("slider-not-mobile")
            const noGap = slider.classList.contains("no-gap")
            const activeSlideNum = Number(window.sessionStorage.getItem("ui_tabs_slider"))
            let startAt = Math.max(
                0,
                [...slider.querySelectorAll(".tab")].findIndex((tab) => tab.classList.contains("active")),
            )

            setTimeout(() => {
                if (Number(activeSlideNum)) window.catSlider.go(`=${Number(activeSlideNum)}`)
            }, 0)

            window.sessionStorage.removeItem("ui_tabs_slider")

            slider.querySelectorAll(".tab > a").forEach((sliderLinkElement) => {
                sliderLinkElement.addEventListener(
                    "click",
                    (event) => {
                        event.preventDefault()
                        if (window.catSlider != null) {
                            window.sessionStorage.setItem("ui_tabs_slider", window.catSlider.index)
                        }
                        window.location = event.currentTarget.href
                    },
                    { passive: true },
                )
            })

            const breakpoints = {
                819: { perView: 1, dragThreshold: 120 },
                1020: { perView: 2, dragThreshold: false },
                1290: { perView: 3, dragThreshold: false },
                50000: { perView: slider.classList.contains("slider-size-6") ? 7 : 4, dragThreshold: false },
            }

            const count = slider.querySelectorAll(".glide__slide").length || slider.querySelectorAll(".tab").length
            let catSlider = null

            const adjustSize = () => {
                if (window.innerWidth > 819 || !noSliderForMobile) {
                    if (!catSlider) {
                        slider.classList.add("has-slider-controls")
                        slider.classList.remove("width-fix")
                        catSlider = new Glide(slider, {
                            type: "slider",
                            perView: slider.classList.contains("slider-size-6") ? 6 : 4,
                            focusAt: 0,
                            startAt: startAt >= 0 ? startAt : 0,
                            gap: noGap ? 0 : 20,
                            bound: true,
                            dragThreshold: false,
                            breakpoints: breakpoints,
                        })
                        catSlider.mount({ Controls, Breakpoints, Swipe })

                        catSlider.on("move.after", moveAfterFunction)

                        slider.querySelectorAll(".slider-control").forEach((glideArrow) => {
                            glideArrow.addEventListener(
                                "click",
                                () => {
                                    catSlider.go(glideArrow.dataset.glideDir)
                                },
                                { passive: true },
                            )
                        })
                    }

                    const hasControls = catSlider.settings.perView < count
                    slider.classList.toggle("has-slider-controls", hasControls)
                    slider.classList.remove("width-fix")
                    catSlider.update(hasControls ? undefined : { startAt: 0 })
                    slider.classList.add("width-fix")
                } else if (catSlider) {
                    const oldCatSlider = catSlider
                    catSlider = null
                    oldCatSlider.destroy()
                    slider.classList.remove("has-slider-controls")
                }
                window.catSlider = catSlider
            }

            adjustSize()
            setTimeout(() => {
                adjustSize()
            }, 2000)
            window.addEventListener("resize", adjustSize, { passive: true })
        })
    },
    { passive: true },
)
