/* global tk */
import("@sass/laraberg/new-packages-grid.scss")
const globalJs = window.globalJs
import Swiper from "swiper"
import { Navigation } from "swiper/modules"
Swiper.use([Navigation])
const Slider = Swiper
const t = globalJs.translations

async function renderGridItem(el, type, typeText) {
    let imageTitle = ""
    let imageAlt = ""
    let imageUrl = ""
    let translate = t.buttons

    if (el.image_featured) {
        imageTitle = el.image_featured.title ? el.image_featured.title : ""
        imageAlt = el.image_featured.alt ? el.image_featured.alt : ""
        imageUrl = el.image_featured.url ? el.image_featured.url : ""
    }

    const cardIcons = renderCardIcons(el)

    return `
    <li class="swiper-slide">
        <div class="card in-view">
        <span class="slide-badge ${type}">${typeText}</span>
            <div class="card-head">
                <div class="card-img pointer">
                    <picture class="cover">
                        <source type="image/avif"
                                srcset=${imageUrl.replace(/\.(jpg|jpeg|png|gif|bmp|tiff|webp)$/i, ".avif")}/>
                        <img alt="${imageAlt}" title="${imageTitle}" loading="lazy"
                             src=${imageUrl.replace(/\.(jpg|jpeg|png|gif|bmp|tiff|avif)$/i, ".webp")}/>
                    </picture>
                </div>
                <div class="card-green-overlay">
                    <div class="card-green-overlay__button">${translate.to_content} <a href="/${globalJs.route.locale}/${globalJs.translations.routes.packages}/${el.locale.package_id}-${el.locale.slug}" class="link-to-click">${globalJs.translations.frontend.to} ${el.locale.title}</a></div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-title clamp" style="overflow: hidden; text-overflow: ellipsis; -webkit-box-orient: vertical; display: -webkit-box; -webkit-line-clamp: 2;">${el.locale.title}</div>
                <p class="clamp" style="overflow: hidden; text-overflow: ellipsis; -webkit-box-orient: vertical; display: -webkit-box; -webkit-line-clamp: 1;">${el.locale.subtitle}</p>
            </div>
            <div class="card-footer">
                <div class="card-icons">
                <div></div>
                    ${cardIcons}
                </div>
            </div>
        </div>
    </li>`
}

async function renderPackagesGrid(item) {
    let response = await globalJs.axios.get(`/${globalJs.route.locale}/package/get-newest-packages-grid`)
    let packagesList = response.data

    let newestPackagesHTML = ""

    for (const el of packagesList) {
        let type = "normal"
        let typeText = ""

        if (Math.ceil((Date.now() - Date.parse(el.locale.first_published_at)) / (1000 * 3600 * 24)) < 11) {
            type = "neu"
            typeText = t.frontend.badge_new
        } else if (
            el.is_public_update &&
            el.public_updated_at &&
            Math.ceil((Date.now() - Date.parse(el.public_updated_at)) / (1000 * 3600 * 24)) < 11
        ) {
            type = "update"
            typeText = t.frontend.badge_update
        }

        newestPackagesHTML += await renderGridItem(el, type, typeText)
        item.querySelector(".new-packages-grid-container").innerHTML = newestPackagesHTML
    }

    let newPackagesGridMobile

    function handleSwiperSlider() {
        if (window.matchMedia("(max-width: 991px)").matches) {
            newPackagesGridMobile = new Slider(".new-packages-grid-container-mobile-slider", {
                slidesPerView: "auto",
                speed: 600,
            })
        } else {
            if (typeof newPackagesGridMobile !== "undefined") {
                newPackagesGridMobile.destroy()
            }
        }
    }

    handleSwiperSlider()

    window.onresize = function () {
        handleSwiperSlider()
    }
}

tk.onLoad(() => {
    let packagesGridLoaded = false
    addEventListener("scroll", () => {
        if (packagesGridLoaded) return
        window.addEventListener("scroll", packagesGridHandleScroll)
        packagesGridLoaded = true
    })
})

async function packagesGridHandleScroll() {
    tk.select(".wp-block-tutkit-new-packages-grid").forEach((item) => {
        renderPackagesGrid(item)
    })
    hideTestBtn()
    hideModelBtn()

    window.removeEventListener("scroll", packagesGridHandleScroll)
}

// Hide test button if user logged in
function hideTestBtn() {
    const testButton = document.querySelectorAll(".hidden-if-logged-in")
    const userLoggedIn = document.querySelector("body").classList.contains("logged-in")
    if (testButton.length && !userLoggedIn) {
        testButton.forEach((el) => (el.style.display = "block"))
    }
}

// Hide price model button if user Starter, Business or Premium
async function hideModelBtn() {
    const modelButton = document.querySelectorAll(".hidden-if-starter-business-premium")
    const models = await globalJs.axios.get("/getuserlicense")
    const userLicence = models.data.license
    if (modelButton.length && !(userLicence === "Starter" || userLicence === "Premium" || userLicence === "Business")) {
        modelButton.forEach((el) => (el.style.display = "flex"))
    }
}

function renderCardIcons(el) {
    if (el.video_length) {
        let iconsHtml = '<span class="icon-label">'

        if (el.video_length >= 3600 && el.video_length < 7200) {
            iconsHtml = iconsHtml + "1 " + t.content.hour + " "
        } else if (el.video_length >= 7200) {
            iconsHtml = iconsHtml + Math.floor(el.video_length / 3600) + " " + t.content.hours + " "
        }

        if (Math.round(el.video_length / 60) % 60 === 1) {
            iconsHtml = iconsHtml + "1 " + t.content.minute
        } else if (Math.round(el.video_length / 60) % 60 > 1) {
            iconsHtml = iconsHtml + (Math.round(el.video_length / 60) % 60) + " " + t.content.minutes
        }

        iconsHtml = iconsHtml + "</span>"

        return iconsHtml
    } else if (el.page_count) {
        return `<span class="icon-label">${el.page_count} ${el.page_count === 1 ? t.content.page : t.content.pages}</span>`
    } else if (el.asset_count) {
        return `<span class="icon-label">${el.asset_count} ${el.asset_count === 1 ? t.content.content : t.content.contents}</span>`
    }
}
