import("@sass/laraberg/image-slider.scss")
import Glide, { Controls, Breakpoints, Swipe } from "@glidejs/glide/dist/glide.modular.esm"

let imageSlider = document.querySelectorAll(".wp-block-tutkit-image-slider")

imageSlider.forEach((slider) => {
    new Glide(slider, {
        type: "carousel",
        perView: 2,
        focusAt: "center",
        startAt: 1,
        gap: "30",
        autoplay: 3000,
        swipeThreshold: 80,
        perTouch: 1,
        touchAngle: 45,
        touchRatio: 0.5,
        animationDuration: 1000,
        breakpoints: {
            1220: {
                perView: 1.6,
            },
            1050: {
                perView: 1.4,
            },
            991: {
                perView: 1.2,
            },
            767: {
                perView: 1,
            },
        },
    }).mount({ Controls, Breakpoints, Swipe })
})
