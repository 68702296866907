document.addEventListener("DOMContentLoaded", () => {
    let inputsCheckbox = document.querySelectorAll(".wp-block-tutkit-newsletter-form.newsletter-form .checkbox.blade")

    inputsCheckbox.forEach(function (input) {
        if (input.parentNode.tagName.toUpperCase() == "LABEL") {
            input.parentNode.addEventListener("click", function () {
                if (!this.querySelector(".checkbox").classList.contains("disabled")) {
                    this.querySelector(".checkbox").classList.toggle("checked")
                    this.querySelector(".checkbox").nextElementSibling.value = this.querySelector(
                        ".checkbox",
                    ).classList.contains("checked")
                        ? "1"
                        : "0"
                }
            })
        } else {
            input.addEventListener("click", function () {
                this.classList.toggle("checked")
                this.nextElementSibling.value = this.classList.contains("checked") ? "1" : "0"
            })
        }
    })

    let inputs = document.querySelectorAll(".form-element.blade")

    inputs.forEach(function (input) {
        input.newsLetterValidate = function () {
            let input = this.querySelector("input, textarea")
            let rules = JSON.parse(this.dataset.validate)
            let inputField = this.querySelector(".input-field")
            if (rules.required && !input.value) {
                inputField.querySelector("span").dataset.original = inputField.querySelector("span").innerText
                inputField.querySelector("span").innerText = rules.required
                inputField.querySelector("span").style.display = "block"
                inputField.classList.add("form-input-top-label")
                inputField.classList.add("form-input-top-label")
                this.classList.add("form-element-error")
                return false
            }

            return true
        }
    })

    let subscribeForm = document.getElementById("subscribe-form")

    if (subscribeForm) {
        let formElement = subscribeForm.querySelectorAll(".form-element")
        for (let i = 0; i < formElement.length; i++) {
            formElement[0].setAttribute("data-validate", '{"required":"Bitte gib deine E-Mail-Adresse ein"}')
            formElement[1].setAttribute("data-validate", '"[]"')
        }

        subscribeForm._token.value = document.querySelector("meta[name=csrf-token]").content

        subscribeForm.addEventListener("input", () => {
            if (subscribeForm.email.value.length > 0 || subscribeForm.firstname.value.length > 0) {
                document.querySelector(".accepted_terms_block").classList.add("visible")
            } else {
                document.querySelector(".accepted_terms_block").classList.remove("visible")
            }
        })

        subscribeForm.addEventListener("submit", function (e) {
            e.preventDefault()

            //required fields
            let validStatus = true
            this.querySelectorAll(".form-element").forEach(function (element) {
                if (element.newsLetterValidate && !element.newsLetterValidate()) {
                    e.preventDefault()
                    validStatus = false
                }
            })

            // accepted_terms
            if (this.accepted_terms != 1) {
                e.preventDefault()
            }

            this.querySelectorAll(".blade.checkbox + input").forEach(function (checkboxInput) {
                let checkboxInputValue = checkboxInput.value
                let checkboxDiv = checkboxInput.previousElementSibling
                checkboxDiv.classList.remove("checkbox-error")
                if (checkboxInput.required && checkboxInputValue !== "1") {
                    checkboxDiv.classList.add("checkbox-error")
                }
            })

            this.querySelectorAll(".error.blade").forEach(function (validation) {
                if (eval(JSON.parse(validation.dataset.if))) {
                    validation.style.display = "block"
                    e.preventDefault()
                } else {
                    validation.style.display = "none"
                }
            })

            if (validStatus && Number(this.accepted_terms.value) > 0) {
                this.submit()
            }
        })
    }
})
