import("@sass/laraberg/categories-overview.scss")
import { isTouchEnabled, shouldBeLazyLoaded } from "@/libs/helpers.js"
import _debounce from "lodash/debounce"

tk.categoriesOverview = (categoriesSection) => {
    return {
        currentCategoryIndex: 0,
        elements: {
            titles: categoriesSection.querySelectorAll(".category-titles > div"),
            images: categoriesSection.querySelectorAll(".category-images > div > picture"),
            imageContainers: categoriesSection.querySelectorAll(".category-images > div"),
            texts: categoriesSection.querySelectorAll(".category-texts > div"),
        },
        touchEnabled: null,
        loadedImages: [],

        async init() {
            this.touchEnabled = isTouchEnabled()
            this.loadImage(0)
            this.bindTitleEvent()
            this.changeActiveCategory()
            this.bindLazyLoad(_debounce, shouldBeLazyLoaded)
        },

        bindTitleEvent() {
            this.elements.titles.forEach((title, index) => {
                title.addEventListener("click", () => this.selectCategory(index))
            }, this)
        },

        selectCategory(index) {
            this.currentCategoryIndex = index
            this.changeActiveCategory()
        },

        changeActiveCategory() {
            if (this.elements.imageContainers.length > 0) {
                this.elements.imageContainers.forEach((container) => container.classList.remove("category-active"))
                this.elements.imageContainers[this.currentCategoryIndex].classList.add("category-active")
            }
            this.elements.titles.forEach((title) => title.classList.remove("category-active"))
            this.elements.titles[this.currentCategoryIndex].classList.add("category-active")
            this.elements.texts.forEach((title) => title.classList.remove("category-active"))
            this.elements.texts[this.currentCategoryIndex].classList.add("category-active")
        },

        bindLazyLoad(_debounce, shouldBeLazyLoaded) {
            if (!this.touchEnabled) {
                this.elements.titles.forEach((title, index) => {
                    title.addEventListener("mouseover", () => {
                        this.loadImage(index)
                    })
                })
            } else {
                window.addEventListener(
                    "scroll",
                    _debounce(() => {
                        if (shouldBeLazyLoaded(categoriesSection)) {
                            this.elements.images.forEach((image, index) => {
                                this.loadImage(index)
                            })
                        }
                    }, 50),
                )
            }
        },

        loadImage(index) {
            if (this.loadedImages.includes(index)) return false
            let activeImage = {}
            index
                ? (activeImage = this.elements.images[index])
                : (activeImage = this.elements.images[this.currentCategoryIndex])
            activeImage && (activeImage.src = activeImage.dataset["backgroundUrl"])
            this.loadedImages.push(index)
        },
    }
}

tk.onLoad(() => {
    tk.select(".wp-block-tutkit-categories-overview").forEach((item) => {
        tk.categoriesOverview(item).init()
    })
})
