window.addEventListener("DOMContentLoaded", async function () {
    const faqs = document.querySelectorAll(".wp-block-tutkit-custom-faq")

    if (faqs.length > 0) {
        faqs.forEach((component) => {
            const questions = component.querySelectorAll(".question_list p")

            questions.forEach(function (question) {
                question.addEventListener("click", function () {
                    // Знімаємо клас active з усіх питань
                    questions.forEach((q) => q.classList.remove("active"))

                    // Додаємо клас active до поточного питання
                    this.classList.add("active")
                })
            })
        })
    }
})
