/* global tk */
import("@sass/laraberg/anchors.scss")

tk.anchorsAuto = function (element) {
    return {
        element: element,
        $elementList: null,
        $body: null,
        $titles: [],

        async init() {
            let list = document.createElement("ul")
            this.$elementList = list
            this.$body = document.querySelector(".blog-post-content, .help-container-article")
            this.$titles = this.$body.querySelectorAll("h2")
            this.$images = this.$body.querySelectorAll('img[loading="lazy"]')
            let wrapperContainer = document.querySelector(".wp-block-tutkit-anchors-auto")

            setTimeout(() => {
                this.$images.forEach((img) => {
                    img.setAttribute("loading", "")
                })
            }, 1000)

            wrapperContainer.append(list)

            this.$titles.forEach((item) => {
                const sanitizedId = item.textContent
                    .replace(/\s+/g, "-")
                    .replace(/[^a-zA-Z-]/g, "")
                    .toLowerCase()
                    .replace(/^-+/g, "")
                item.setAttribute("id", sanitizedId)
                let listItem = document.createElement("li")
                let listItemLink = document.createElement("a")
                listItemLink.innerText = item.textContent
                listItemLink.href = "#" + sanitizedId
                listItem.append(listItemLink)
                list.append(listItem)
            })

            this.$elementList.querySelectorAll('a[href^="#"]').forEach((anchor) => {
                anchor.addEventListener("click", function (e) {
                    e.preventDefault()
                    let menuHeight_compensetion = document.querySelectorAll("header.site-header")[0].clientHeight
                    let anchorBlock = document.querySelector(anchor.getAttribute("href"))
                    let headerOffset = menuHeight_compensetion
                    let elementPosition = anchorBlock.offsetTop
                    let offsetPosition = elementPosition - headerOffset - 20
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth",
                    })
                })
            })

            return this
        },
    }
}

tk.onLoad(function () {
    let element = tk.class(".wp-block-tutkit-anchors-auto")
    if (element) {
        tk.anchorsAuto(element).init()
    }
})
