/* global tk */

window.tk = {}
tk.id = function (key) {
    return document.getElementById(key)
}
tk.select = function (key) {
    return document.querySelectorAll(key)
}
tk.class = function (key) {
    return document.querySelector(key)
}
tk.create = function (tag, options) {
    return document.createElement(tag, options)
}
tk.onLoad = function (e) {
    window.addEventListener("load", e)
}
