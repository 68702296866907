import "@sass/laraberg/products-slider.scss"
import { isTouchEnabled } from "@/libs/helpers.js"
import Swiper from "swiper"
import { Navigation } from "swiper/modules"

Swiper.use([Navigation])

document.addEventListener("DOMContentLoaded", () => {
    if ("requestIdleCallback" in window) {
        requestIdleCallback(() => {
            initProductsSlider()
        })
    } else {
        setTimeout(() => {
            initProductsSlider()
        }, 1000)
    }
})

async function initProductsSlider() {
    const selectAll = (selector) => document.querySelectorAll(selector)

    const productsSlider = (categoriesSection, index) => {
        return {
            currentCategoryIndex: 0,
            elements: {
                packages: categoriesSection.querySelector(".packages"),
            },
            touchEnabled: isTouchEnabled(),
            loadedImages: [],

            async init() {
                await this.renderPackage(index)
                this.initSlider(index)
            },

            async renderPackage(index) {
                const packages = this.elements.packages
                const productsArray = packages.innerHTML.split(/\s*,\s*/)

                const headers = {
                    "Content-Type": "application/json; charset=UTF-8",
                    "X-CSRF-TOKEN": document.querySelector("meta[name=csrf-token]").content,
                    "X-Robots-Tag": "noindex, nofollow",
                }

                try {
                    const response = await window.globalJs.axios.post(
                        `/${window.globalJs.route.locale}/get-products`,
                        productsArray,
                        { headers },
                    )

                    if (response) {
                        const html = `
                            <div class="content-slider-products swiper swiper-container-${index}">
                                <div class="swiper-wrapper">
                                    ${response.data
                            .map((singlePackage) => {
                                if (singlePackage) {
                                    const imagePath = `/storage/media/${singlePackage.image_featured.path.replace(/\.(jpg|jpeg|png|gif|bmp|tiff|webp)$/i, ".webp")}`
                                    const imageAvifPath = `/storage/media/${singlePackage.image_featured.path.replace(/\.(jpg|jpeg|png|gif|bmp|tiff|webp)$/i, ".avif")}`
                                    return `
                                                <div class="swiper-slide">
                                                    <div class="card">
                                                        <div>
                                                            <div class="card-head">
                                                                <div class="card-img">
                                                                    <picture class="cover">
                                                                        <source type="image/avif" srcset="${imageAvifPath}"/>
                                                                        <img alt="${singlePackage.title}" title="${singlePackage.title}" src="${imagePath}" loading="lazy"/>
                                                                    </picture>
                                                                </div>
                                                                <div class="card-green-overlay">
                                                                    <div class="card-green-overlay__button">
                                                                        ${window.globalJs.translations.buttons.to_content}
                                                                        <a href="${singlePackage.url}" class="link-to-click">
                                                                            ${window.globalJs.translations.frontend.to} ${singlePackage.title}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card-body">
                                                                <p class="clamp">${singlePackage.title}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            `
                                }
                            })
                            .join("")}
                                </div>
                            </div>
                            <div class="swiper-button-prev prev swiper-button-prev-${index}"></div>
                            <div class="swiper-button-next next swiper-button-next-${index}"></div>
                        `
                        packages.innerHTML = html
                    }
                } catch (error) {
                    console.error("Error fetching packages:", error)
                }
            },

            initSlider(index) {
                const slider = this.elements.packages.querySelector(`.swiper-container-${index}`)

                const responseSettings = {
                    auto: "auto",
                    mob: 2,
                    tab: 3,
                    deck: 4,
                    deckFull: 4,
                }

                if (
                    document.querySelector("main > div").classList.contains("help-container-article") ||
                    document.querySelector("main > div").classList.contains("text-tutorial-wrapper")
                ) {
                    responseSettings.auto = 1
                    responseSettings.mob = 2
                    responseSettings.tab = 2
                    responseSettings.deck = 3
                    responseSettings.deckFull = 4
                }

                const swiperInstance = new Swiper(slider, {
                    slidesPerView: responseSettings.auto,
                    centeredSlides: false,
                    spaceBetween: 20,
                    effect: "slide",
                    direction: "horizontal",
                    speed: 1000,
                    loop: true,
                    keyboard: true,
                    a11y: true,
                    navigation: {
                        nextEl: `.swiper-button-next-${index}`,
                        prevEl: `.swiper-button-prev-${index}`,
                    },
                    breakpoints: {
                        576: { slidesPerView: responseSettings.mob },
                        992: { slidesPerView: responseSettings.tab },
                        1290: { slidesPerView: responseSettings.deck },
                        1530: { slidesPerView: responseSettings.deckFull },
                    },
                    on: {
                        init: function () {
                            const totalSlides = this.slides.length
                            const visibleSlides = this.params.slidesPerView
                            if (this.navigation && this.navigation.nextEl && this.navigation.prevEl) {
                                if (totalSlides <= visibleSlides) {
                                    this.navigation.nextEl.style.display = "none"
                                    this.navigation.prevEl.style.display = "none"
                                } else {
                                    this.navigation.nextEl.style.display = "block"
                                    this.navigation.prevEl.style.display = "block"
                                }
                            }
                        },
                        resize: function () {
                            const totalSlides = this.slides.length
                            const visibleSlides = this.params.slidesPerView
                            if (this.navigation && this.navigation.nextEl && this.navigation.prevEl) {
                                if (totalSlides <= visibleSlides) {
                                    this.navigation.nextEl.style.display = "none"
                                    this.navigation.prevEl.style.display = "none"
                                } else {
                                    this.navigation.nextEl.style.display = "block"
                                    this.navigation.prevEl.style.display = "block"
                                }
                            }
                        },
                    },
                })
            },
        }
    }

    selectAll(".wp-block-tutkit-products-slider").forEach((item, index) => {
        const sliderInstance = productsSlider(item, index)
        sliderInstance.init()
    })
}
